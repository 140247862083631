<template>
<div id="page-sucursales">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Sucursales" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nueva" }} Sucursal</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
              <v-col sm="10" md="10" lg="10">
                <v-text-field label="Nombre" v-model="model.nombre" @input="upperCaseInput('nombre')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="Alta" v-model="fechaUsuario" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                <v-checkbox v-model="model.abastos" label="Es Sucursal Abastos?"
                  @change="parseInt(model.abastos)==1 ? model.matriz=0 : model.matriz=model.matriz"
                  :true-value="1" :false-value="0"></v-checkbox>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                <v-checkbox v-model="model.matriz" label="Es Sucursal Matriz?"
                  @change="parseInt(model.matriz)==1 ? model.abastos=0 : model.abastos=model.abastos"
                  :true-value="1" :false-value="0"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="3" md="3" lg="3">
                <v-text-field label="Clave Sucursal" v-model="model.clave" @input="upperCaseInput('clave')" :rules="[rules.required]" maxlength="1"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-text-field label="Teléfono 1" v-model="model.telefono1" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-text-field label="Teléfono 2" v-model="model.telefono2"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-text-field label="Fax" v-model="model.fax"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8" md="8" lg="8">
                <v-text-field label="Domicilio" v-model="model.domicilio" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="Ext." v-model="model.numero_exterior" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="Int." v-model="model.numero_interior"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                <v-text-field label="Colonia" v-model="model.colonia" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                <v-text-field label="Ciudad" v-model="model.ciudad" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8" md="8" lg="8">
                <v-text-field label="Referencias" v-model="model.referencias" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="CP" v-model="model.codigo_postal" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.utilerias.sucursales')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
    this.getSucursales();
  },
  data() {
    return {
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      columns: [
        "nombre","clave",
        "telefono1",
        "fax",
        "estatus",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["nombre","clave", "telefono1", "fax", "estatus"],
        sortable: ["nombre","clave", "telefono1", "fax",  "estatus"],

        headings: {
          nombre: "Nombre",
          clave:"Clave",
          "telefono1": "Telefono1",
          "fax": "Fax",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns:{
        estatus: [{
                    id: 'Activo',
                    text: "Activo"
                },
                {
                    id: 'Inactivo',
                    text: "Inactivo"
                }
            ]
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Utilerías",
          disabled: true,
          href: ""
        },
        {
          text: "Sucursales",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        _id: "",
        _rev: "",
        type:"sucursales",
        nombre: "",
        clave:"",
        created_at: "",
        estatus: "Activo",
        telefono1: "",
        telefono2: "",
        fax: "",
        domicilio: "",
        numero_exterior: "",
        numero_interior: "",
        colonia: "",
        ciudad: "",
        codigo_postal: "",
        referencias: "",
        abastos:0, //Para saber si es la sucursal de Abastos, se utiliza en compras para separar la mercancia del proveedor y al momento de recibirla en sucursal
        matriz:0, //Para saber si es la sucursal Matriz, se utiliza en compras para separar la mercancia del proveedor y al momento de recibirla en sucursal
        autoincrementables: [
          {"tabla": "lotes_traspasos","anio":2021,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2022,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2023,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2024,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2025,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2026,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2027,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2028,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2029,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2030,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2031,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2032,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2033,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2034,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2035,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2036,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2037,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2038,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2039,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2040,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2041,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2042,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2043,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2044,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2045,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2046,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2047,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2048,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2049,"auto_increment": 0},
          {"tabla": "lotes_traspasos","anio":2050,"auto_increment": 0}          
        ],
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      sucursales_all:[],      
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    onStatusChanged(event) {
      this.registros.items = []; 
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"sucursales"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            //sort: ["nombre"]
            use_index: "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    openModal: function(type) {
      if (type === 1) {
        this.update = false;
        this.model._id = "";
        this.model.nombre = "";
        this.model.clave = "";
        this.model.estatus = "Activo";
        this.model.telefono1 = "";
        this.model.telefono2 = "";
        this.model.fax = "";
        this.model.abastos = 0;
        this.model.matriz = 0;
        this.model.domicilio = "";
        this.model.numero_exterior = "";
        this.model.numero_interior = "";
        this.model.colonia = "";
        this.model.ciudad = "";
        this.model.codigo_postal = "";
        this.model.referencias = "";
        this.model.created_at = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
      } else {
        this.update = true;
      }

      this.modal = true;
    },

    getSucursales: function() {

      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {"type":"sucursales"},
        "fields": ["_id", "nombre","abastos","matriz"],
        "use_index": "b8a585e5960e3dc7ff5750a94583c66cb3efa22e"
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.sucursales_all = response.data.docs;
          else
            this.registros.items = [];
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las sucursales.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },

    getRegistro: function(id_registro) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/" + id_registro)
        .then(response => {
          this.model = response.data;
          this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
          this.openModal(2);
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    validaUnicos: async function(){
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
          await self.validaNombre().then(result => {
          }).catch(err => {
            duplicados.push("Nombre");
          });

          await self.validaClave().then(result => {
          }).catch(err => {
            duplicados.push("Clave");
          });

          if(duplicados.length > 0)
            return reject(duplicados);
          else
            return resolve(true);

      });

    },

    validaNombre: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.nombre != null && self.model.nombre!="") {
            var busca = encodeURI(escape(self.model.nombre.toString().trim().toLowerCase()));
            window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/sucursales/_view/nombre?key=\"'+busca+'\"')
              .then(response => {

                  if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                    if(self.model._id != ""){//Esta editando
                      var filter = response.data.rows.find( r => {
                          return self.model._id != r.id;
                      });
                      if(filter){
                        return reject(false);
                      }
                    } else { //Es nuevo
                        return reject(false);
                    }
                  }
                  return resolve(true);
              })
              .catch(err => {
                  return reject(false);
              });
          } else
            return resolve(true);
      });
    },

    validaClave: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.clave != null && self.model.clave!="") {
            var busca = encodeURI(escape(self.model.clave.toString().trim().toLowerCase()));
            window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/sucursales/_view/clave?key=\"'+busca+'\"')
              .then(response => {

                  if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                    if(self.model._id != ""){//Esta editando
                      var filter = response.data.rows.find( r => {
                          return self.model._id != r.id;
                      });
                      if(filter){
                        return reject(false);
                      }
                    } else { //Es nuevo
                        return reject(false);
                    }
                  }
                  return resolve(true);
              })
              .catch(err => {
                  return reject(false);
              });
          } else
            return resolve(true);
      });
    },

    saveRegistro: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then( result => {

            let data = {
              type: this.model.type,
              nombre: this.model.nombre.toString().trim(),
              clave: this.model.clave.toString().trim(),
              created_at: this.model.created_at,
              estatus: this.model.estatus,
              telefono1: this.model.telefono1,
              telefono2: this.model.telefono2 != null ? this.model.telefono2.toString().trim() : "",
              fax: this.model.fax != null ? this.model.fax.toString().trim() : "",
              domicilio: this.model.domicilio,
              numero_exterior: this.model.numero_exterior,
              numero_interior: this.model.numero_interior != null ? this.model.numero_interior.toString().trim() : "",
              colonia: this.model.colonia != null ? this.model.colonia.toString().trim() : "",
              ciudad: this.model.ciudad != null ? this.model.ciudad.toString().trim() : "",
              codigo_postal: this.model.codigo_postal != null ? this.model.codigo_postal.toString().trim() : "",
              referencias: this.model.referencias != null ? this.model.referencias.toString().trim() : "",
              abastos: parseInt(this.model.abastos),
              matriz: parseInt(this.model.matriz),
              autoincrementables: this.model.autoincrementables,
            };

            window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
              .then(response => {

                if(data["abastos"] == 1){
                  this.set_abastos(response.data.id);
                } else if(data["matriz"] == 1){
                  this.set_matriz(response.data.id);
                } else {
                  this.registros.items = [];
                  this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                }
                this.modal = false;
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                  color: 'success'
                });

              })
              .catch(error => {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al guardar el registro.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
              });
          }).catch( err => {

            window.dialogLoader.hide();
            var mensaje = "";
            if(err.length > 1)
              mensaje = err.join(', ')+" ya existen. Favor de verificar.";
            else
              mensaje = err.join(', ')+" ya existe. Favor de verificar.";

            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: mensaje,
              footer: ""
            });
        });
      }
    },
    updateRegistro: function() {

      if (this.$refs.form.validate()) {
          window.dialogLoader.show('Espere un momento por favor..');
          this.validaUnicos().then( result => {

            let data = this.model;
            data["abastos"] = parseInt(this.model.abastos);
            data["matriz"] = parseInt(this.model.matriz);
            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
              .then(response => {
                if(data["abastos"] == 1){
                  this.set_abastos(response.data.id);
                } else if(data["matriz"] == 1){
                  this.set_matriz(response.data.id);
                } else {
                  this.registros.items = [];
                  this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                }
                window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                  color: 'success'
                });

              })
              .catch(error => {
                window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                  color: 'error'
                });
              }).then(() => {
                this.modal = false;
                window.dialogLoader.hide();
              });
          }).catch( err => {

            window.dialogLoader.hide();
            var mensaje = "";
            if(err.length > 1)
              mensaje = err.join(', ')+" ya existen. Favor de verificar.";
            else
              mensaje = err.join(', ')+" ya existe. Favor de verificar.";

            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: mensaje,
              footer: ""
            });
        });
      }
    },
    set_abastos: async function(id_sucursal){
      for(var k in this.sucursales_all){
          if(this.sucursales_all[k]._id != id_sucursal){
            var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_sucursales/_update/set_abastos/" + this.sucursales_all[k]._id;
            var data = {
              abastos: 0
            };
            await window.axios
              .post(url, data)
              .catch(error => {
                console.log("Error en set_abastos: ", error);
              });
          }
      }
        this.registros.items = [];
        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    set_matriz: async function(id_sucursal){
      for(var k in this.sucursales_all){
          if(this.sucursales_all[k]._id != id_sucursal){
            var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_sucursales/_update/set_matriz/" + this.sucursales_all[k]._id;
            var data = {
              matriz: 0
            };
            await window.axios
              .post(url, data)
              .catch(error => {
                console.log("Error en set_matriz: ", error);
              });
          }
      }
        this.registros.items = [];
        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    deleteRegistro: async function  (_id, _rev) {
      let self = this;
      let data = {
          "selector": { "sucursal._id":_id },
          "fields": [ "_id"],          
          "limit":1
      };

     await window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find/', data)
        .then( async response => {
          console.log(response.data);
          if(response.data.docs.length > 0){
            window.dialogLoader.showSnackbar('La sucursal contiene usuarios imposible eliminarla.', { color: 'error' } );
          }else{            
            let data2 = {
                  "selector": { 
                    "type":"ventas", 
                    "sucursal._id":_id,
                    "ticket":{"$exists":true} 
                  },
                  "fields": [ "_id"],
                  "use_index": "_design/a7d2e051b59b11b94b48688c00647000ac8326f9",
                  "limit":1
              };

            await  window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data2)
                .then(async response => {
                  console.log(response.data);
                  if(response.data.docs.length > 0){
                    window.dialogLoader.showSnackbar('La sucursal contiene ventas imposible eliminarla.', { color: 'error' } );
                  }else{
                    this.$swal({
                      type: "info",
                      title: "Cuidado",
                      text: "¿Está seguro de eliminar el registro?",
                      footer: "",
                      showCancelButton: true,
                      cancelButtonColor: "#d33",
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "Aceptar",
                      cancelButtonText: "Cancelar"
                    }).then(result => {
                    if (result.value ) {
                          window.dialogLoader.show('Espere un momento por favor..');
                          window.axios
                            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/" + _id + "?rev=" + _rev)
                            .then(response => {
                              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                                color: 'success'
                              });
                              self.registros.items = [];
                              self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            })
                            .catch(error => {
                              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                                color: 'error'
                              });
                            }).then(()=>{
                              window.dialogLoader.hide();
                            });

                        }

                      });
                  }
                })
                .catch(error => {
                      console.log(err);
                });

        }
      })
      .catch(error => {
            console.log(err);
      });



    },

  }
};
</script>
